import * as React from "react"
import SupportCategoryTitle from "components/atoms/SupportCategoryTitle"
import Grid from "components/PageBuilder/Common/Grid"
import { IconsId } from "assets/fonts/iconfont/icons"
import Container from "components/atoms/Container"
import Box from "components/molecules/Box"
import Icon from "components/atoms/Icon"
import { useTranslation } from "react-i18next"

type Props = {}

const ContactArticles: React.ComponentType<Props> = ({}) => {
  const { t, i18n } = useTranslation()
  const articles = [
    {
      id: 1,
      name: t("support"),
      url: i18n.language === "en" ? "/en/support" : "/podpora",
      icon: "hotline",
    },
    {
      id: 2,
      name: t("billing"),
      url:
        i18n.language === "en" ? "/en/support/billing" : "/podpora/vyuctovani",
      icon: "billing",
    },
    {
      id: 3,
      name: t("aboutUs"),
      url: i18n.language === "en" ? "/en/about-us" : "/o-spolecnosti",
      icon: "networking",
    },
  ]
  return (
    <Container>
      <div className={"my-10"}>
        <SupportCategoryTitle title={t("moreContacts")} />
        <div className={"sm:m-10 sm:mt-0 mt-4"}>
          <Grid xs={1} sm={2} lg={3} gap={"2rem"}>
            {articles.map(article => {
              return (
                <Box
                  key={article.id}
                  link={article.url}
                  outline
                  buttonTitle={t("findOutMore")}
                  fitting
                >
                  <div
                    className={
                      "flex flex-col md:flex-row items-start md:items-center relative group  mb-10 p-4"
                    }
                  >
                    {article.icon && (
                      <Icon
                        icon={article.icon as IconsId}
                        color={"text-quantcomBlue"}
                        size={"text-4xl"}
                      />
                    )}

                    <h3
                      className={
                        "text-quantcomDarkBlue font-black text-2xl md:ml-4 mt-4 md:mt-0"
                      }
                    >
                      {article.name}
                    </h3>
                  </div>
                </Box>
              )
            })}
          </Grid>
        </div>
      </div>
    </Container>
  )
}

export default ContactArticles
