import * as React from "react"
import SEO from "components/seo"
import { PageContextT } from "../types/Common"
import Layout from "../components/organisms/Layout"
import PageBuilderWrapper from "../components/PageBuilder/PageBuilderWrapper"
import Map from "components/atoms/Map"
import ContactArticles from "components/molecules/ContactArticles"
import { useTranslation } from "react-i18next"

type Props = {
  pageContext: PageContextT
}

const Contact: React.ComponentType<Props> = (props: Props) => {
  const {
    pageContext: {
      page,
      mainHeaderLinks,
      otherHeaderLinks,
      navigationLinks,
      footerSections,
      socialLinks,
      commonLinks,
      uploadedFiles,
      contactForms,
      configurations,
      mobileHeaderLinks,
      localizedPage,
    },
  } = props

  const activePage = otherHeaderLinks.find(link => link.page.id === page.id)
  const { i18n } = useTranslation()

  return (
    <Layout
      mainHeaderLinks={mainHeaderLinks}
      otherHeaderLinks={otherHeaderLinks}
      navigationLinks={navigationLinks}
      footerSections={footerSections}
      socialLinks={socialLinks}
      commonLinks={commonLinks}
      configurations={configurations}
      mobileHeaderLinks={mobileHeaderLinks}
      activePage={activePage}
      breadCrumbs={[]}
      localizedPage={localizedPage}
    >
      <SEO
        title={page.SEO?.title || page.title}
        description={page.SEO?.description}
      />
      {page.pageBuilderContentLocation === "beforeTemplate" && (
        <PageBuilderWrapper
          text={page.text}
          uploadedFiles={uploadedFiles}
          configurations={configurations}
          contactForms={contactForms}
          socialLinks={socialLinks}
        />
      )}
      <ContactArticles />
      <Map />
      {page.pageBuilderContentLocation === "afterTemplate" && (
        <PageBuilderWrapper
          text={page.text}
          uploadedFiles={uploadedFiles}
          contactForms={contactForms}
          configurations={configurations}
        />
      )}
    </Layout>
  )
}

export default Contact
