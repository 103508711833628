import React from "react"
import classNames from "classnames"
import Container from "./Container"
import { useTranslation } from "react-i18next"
import SupportCategoryTitle from "./SupportCategoryTitle"

type Props = {}

const markers = [
  {
    id: 1,
    title: "Praha (centrála)",
    lat: 50.09247874138937,
    lng: 14.451138553818154,
    street: "Křižíkova 36a/237",
    city: "Praha 8",
    zip: "186 00",
  },
  {
    id: 2,
    title: "Severní Čechy",
    lat: 50.66248939627762,
    lng: 14.041020926855802,
    street: "Dvořákova 2/3134",
    city: "Ústí nad Labem",
    zip: "400 01",
  },
  {
    id: 3,
    title: "Morava",
    lat: 49.16335620787738,
    lng: 16.592470955631054,
    street: "Traťová 4",
    city: "Brno",
    zip: "619 00",
  },
  {
    id: 4,
    title: "Slovensko",
    lat: 48.15689295062818,
    lng: 17.10502135559112,
    street: "Pražská 11,1. poschodí",
    city: "Bratislava",
    zip: "811 04",
  },
]

const Map: React.ComponentType<Props> = () => {
  const { t } = useTranslation()

  return (
    <div className={"my-10"}>
      <Container>
        <SupportCategoryTitle title={t("branches")} />
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-10 mt-10">
          {markers.map(marker => {
            return (
              <div className={"bg-quantcomBlue p-8"} key={marker.id}>
                <div className={"flex justify-between text-xl cursor-pointer"}>
                  <div className={"text-white text-lg mb-5 font-light"}>
                    {marker.title}
                  </div>
                </div>
                <div className={classNames("text-white")}>
                  <p>{marker.street}</p>
                  <p>
                    {marker.city} {marker.zip}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
        <div>
          <iframe style={{ width: "100%", height: "24rem", "margin-top": "2rem" }}
                  src="https://www.google.com/maps/d/u/0/embed?mid=1EtCByMQ4Vb1xe-QLFedKMK9TaItPYdE&ehbc=2E312F&noprof=1"></iframe>
        </div>
      </Container>
    </div>
  )
}

export default Map
